import styled from 'styled-components';
import { rgbaToHex } from '../../utils/color';

export const Container = styled.div`
  & .carousel.carousel-slider {
    min-height: 285px;
    overflow: visible;
    position: relative;
  }
  & .carousel .slide img {
    max-width: 390px;
    width: 100%;
  }
  & .carousel .thumbs {
    padding: 0;
    text-align: center;
  }
  & .carousel .thumb {
    margin-right: auto;
    margin-left: auto;
    padding: 0;
    cursor: pointer;
  }
  & .carousel .thumb:hover {
    border: 3px solid #d26546;
  }
  & .carousel .thumb.selected {
    border: 3px solid #d26546;
    opacity: 1;
  }
  & .carousel .thumbs-wrapper {
    margin: 0;
  }
  
  & .carousel .control-dots {
    position: absolute;
    bottom: -24px;
    margin: 0;
    display: flex;
    justify-content: center;
    padding: 0;
    text-align: center;
    z-index: 1;
  }

  & .thumbs {
    transform: none!important;
  }

  ${({ pageDesign }) => {
    switch (pageDesign) {
      case 'design2':
        return `
            & .carousel .thumb:hover {
                border: 3px solid #AD3B42;
            }
            & .carousel .thumb.selected {
                border: 3px solid #AD3B42;
            }
            & .carousel li.slide > div {
                margin: 0 10px;
            }
            `;
      default:
        return;
    }
  }}

  ${({ arrowSettings }) => {
    if (arrowSettings) {
      const {
        arrowBgColor,
        arrowColor,
        arrowHoverBgColor,
        arrowHoverOpacity,
        arrowOpacity
      } = arrowSettings;

      return `
        & .carousel .control-prev.control-arrow:before {
            border-right: 8px solid ${arrowColor ?? '#FFF'};
        }
        & .carousel .control-next.control-arrow:before {
            border-left: 8px solid ${arrowColor ?? '#FFF'};
        }
        & .carousel.carousel-slider .control-arrow:hover {
            background-color: ${arrowHoverBgColor ?? 'rgba(0, 0, 0, 0.2)'};
            opacity: ${(arrowHoverOpacity ?? 40) / 100}
        }
        & .carousel.carousel-slider .control-arrow {
            background-color: ${arrowBgColor ?? 'rgba(0, 0, 0, 0.2)'};
        }
        & .carousel .control-arrow, 
        & .carousel.carousel-slider .control-arrow {
            opacity: ${(arrowOpacity ?? 40) / 100}
        }
        `;
    }
  }}

  ${({ arrowSettings }) => {
    if (!arrowSettings) return ``;
    const { arrowStyle, arrowColor = '#001C72' } = arrowSettings;
    const arrowColorHex = rgbaToHex(arrowColor);
    const svgArrowPrev = `<svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.835 18.13L10.065 19.9L0.165022 10L10.065 0.100006L11.835 1.87001L3.70502 10L11.835 18.13H11.835Z" fill="${arrowColorHex}"/></svg>`;
    const svgArrowNext = `<svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.164993 18.13L1.93499 19.9L11.835 10L1.93499 0.100006L0.164993 1.87001L8.29499 10L0.164993 18.13H0.164993Z" fill="${arrowColorHex}"/></svg>`;
    switch (arrowStyle) {
      case '<LTS - GTS>':
        return `
          & .carousel .control-arrow {
            padding: 0;
          }
          & .carousel .control-arrow.control-prev:before {
            border: none!important;
            width: 12px;
            height: 20px;
            background: url(data:image/svg+xml,${encodeURIComponent(
              svgArrowPrev
            )}) transparent no-repeat;
          }
          & .carousel .control-arrow.control-next:before {
            border: none!important;
            width: 12px;
            height: 20px;
            background: url(data:image/svg+xml,${encodeURIComponent(
              svgArrowNext
            )}) transparent no-repeat;
          }
        `;
      case '<SM - CEN>':
        return `
          & .carousel .control-arrow {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 30px;
            padding: 0;
            border-radius: 4px;
            position: absolute;
            top: 50% !important;
            transform: translateY(-50%);   
          }
          & .carousel.carousel-slider .control-arrow {
            @media(max-width: 500px) {
              background: rgba(255, 255, 255, 0.5);
              border-radius: 0;
            } 
          }
          & .carousel .control-disabled.control-arrow {
            display: none;
          }
          & .carousel.carousel-slider .control-arrow:hover {
            @media(max-width: 500px) {
              background: rgba(255, 255, 255, 0.5);
              opacity: 100;
            } 
          }
          & .carousel .control-arrow:before, & .carousel.carousel-slider .control-arrow:before {
            @media(max-width: 500px) {
              margin: 0
            } 
          }
          & .carousel .control-arrow.control-prev:before {
            border: none!important;
            width: 12px;
            height: 20px;
            background: url(data:image/svg+xml,${encodeURIComponent(
              svgArrowPrev
            )}) transparent no-repeat;
          }
          & .carousel .control-arrow.control-next:before {
            border: none!important;
            width: 12px;
            height: 20px;
            background: url(data:image/svg+xml,${encodeURIComponent(
              svgArrowNext
            )}) transparent no-repeat;
          }
        `;
      case 'default':
      default:
        break;
    }
  }}

  ${({ indicatorsHideOnDesktop }) => {
    if (indicatorsHideOnDesktop) {
      return `
        @media (min-width: 500px) {
          & .carousel .control-dots {
            display: none;
          }
        }
      `;
    }
  }}
  ${({ thumbsHideOnMobile }) => {
    if (thumbsHideOnMobile) {
      return `
        @media (max-width: 500px) {
          & .carousel {
            display: none;
          }
          & .carousel.carousel-slider {
            display: inherit;
          }
        }
      `;
    }
  }}
  ${({ thumbsPosition }) => {
    switch (thumbsPosition) {
      case 'left':
        return `
          & .carousel-root {
            display:flex;
            flex-direction:row-reverse;
            justify-content: space-around;
          }
          & .thumbs {
            display:flex;
            flex-direction: column;
          }
          & .carousel {
            width: 20%;
          }
          & .thumbs-wrapper > .control-arrow {
            display: none;
          }
        `;
      case 'right':
        return `
        & .carousel-root {
          display:flex;
          flex-direction:row;
        }
        & .thumbs {
          display:flex;
          flex-direction: column;
        }
        & .carousel {
          width: 20%;
        }
        `;
      case 'top':
        return `
        & .carousel-root {
          display:flex;
          flex-direction:column-reverse;
        }
        `;
      case 'bottom':
      default:
        return ``;
    }
  }};

  @media (max-width: 500px) {
    .carousel.carousel-slider .control-arrow {
      opacity: 1;
    }
  }

`;
