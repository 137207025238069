import React, { useContext } from 'react';
import * as S from './ImageCarousel.styles';
import { Carousel } from 'react-responsive-carousel';
import FunnelContext from '../../context/FunnelContext';
import { Image } from '@builder.io/react';

import 'react-responsive-carousel/lib/styles/carousel.css';

const ImageCarouselWrapper = props => {
  const { images, settings } = props;

  const thumbWidth = parseInt(settings?.thumbWidth ?? 80);
  const thumbsPosition = settings?.thumbsPosition || 'bottom';

  const { pageDesign } = useContext(FunnelContext);

  const renderThumbs = () => {
    return images.map((image, index) => {
      const imageAlt = image.alt ? image.alt : `Carousel Thumb ${index}`;

      return (
        <div key={index} className={thumbsPosition}>
          <img
            src={`${image.image}?width=${thumbWidth}`}
            alt={imageAlt}
            width={thumbWidth}
            height="100%"
          />
        </div>
      );
    });
  };

  const renderIndicator = (onClickHandler, isSelected, index, label) => {
    const defStyle = {
      width: '8px',
      height: '8px',
      margin: '8px 8px',
      borderRadius: '50%',
      background: '#ccd2e3',
      cursor: 'pointer'
    };
    const style = isSelected
      ? { ...defStyle, background: '#001c72' }
      : { ...defStyle };
    return (
      <div
        style={style}
        onClick={onClickHandler}
        onKeyDown={onClickHandler}
        key={index}
        role="button"
        tabIndex={0}
        aria-label={`${label} ${index + 1}`}
      />
    );
  };

  return (
    <S.Container
      pageDesign={pageDesign}
      arrowSettings={settings?.arrowSettings}
      thumbsPosition={thumbsPosition}
      thumbsHideOnMobile={settings?.thumbsHideOnMobile}
      indicatorsHideOnDesktop={settings?.indicatorsHideOnDesktop}
    >
      <Carousel
        showStatus={settings?.showStatus ?? false}
        thumbWidth={thumbWidth}
        swipeScrollTolerance={settings?.swipeScrollTolerance ?? 5}
        autoPlay={settings?.autoPlay ?? false}
        centerMode={settings?.centerMode ?? false}
        dynamicHeight={settings?.dynamicHeight ?? false}
        emulateTouch={settings?.emulateTouch ?? false}
        infiniteLoop={settings?.infiniteLoop ?? false}
        interval={settings?.interval ?? 3000}
        preventMovementUntilSwipeScrollTolerance={
          settings?.preventMovementUntilSwipeScrollTolerance ?? true
        }
        stopOnHover={settings?.stopOnHover ?? false}
        swipeable={settings?.swipeable ?? true}
        useKeyboardArrows={settings?.useKeyboardArrows ?? false}
        selectedItem={settings?.selectedItem ?? 0}
        showArrows={settings?.showArrows ?? true}
        showIndicators={settings?.showIndicators ?? true}
        showThumbs={settings?.showThumbs ?? true}
        ariaLabel={settings?.ariaLabel ?? ''}
        axis={settings?.axis ?? 'horizontal'}
        transitionTime={settings?.transitionTime ?? 500}
        renderThumbs={renderThumbs}
        renderIndicator={renderIndicator}
      >
        <img
          src={`${images[0].image}?width=500`}
          alt={images[0].alt ? images[0].alt : `Carousel 0`}
          width={390}
          height={390}
          style={{ width: '100%', height: 'auto' }}
        />
        {images.slice(1).map((image, index) => {
          const imageAlt = image.alt ? image.alt : `Carousel ${index}`;

          return (
            <div key={index}>
              <Image
                image={`${image.image}?width=500`}
                lazy={true}
                alt={imageAlt}
                width={390}
                height={390}
                style={{ width: '100%', height: 'auto' }}
              />
            </div>
          );
        })}
      </Carousel>
    </S.Container>
  );
};

export default ImageCarouselWrapper;
